import React, { useState } from 'react';
import { ChevronRight, ChevronLeft, Loader2, X } from 'lucide-react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('336340599280167', options);

const quizData = {
  questions: [
    {
      id: 1,
      question: "Wat is jouw belangrijkste fitnessdoel?",
      options: [
        { id: 'a', text: "Meer spiermassa opbouwen", result: "muscle_mass" },
        { id: 'b', text: "Meer kracht ontwikkelen", result: "strength" },
        { id: 'c', text: "Afvallen en strakker worden", result: "fat_loss" },
        { id: 'd', text: "Meer energie en fitter voelen", result: "better_health" },
        { id: 'e', text: "Ik weet het niet zeker, ik wil gewoon een goed plan", result: "clear_plan" }
      ]
    },
    {
      id: 2,
      question: "Hoe train je op dit moment?",
      options: [
        { id: 'a', text: "Ik heb een plan, maar ik weet niet of het werkt", result: "doubting_plan" },
        { id: 'b', text: "Ik train, maar zonder echt een plan", result: "no_plan" },
        { id: 'c', text: "Soms wel, soms niet – ik ben niet consistent", result: "inconsistent_plan" },
        { id: 'd', text: "Ik heb nog nooit echt een serieus plan gevolgd", result: "never_had_plan" }
      ]
    },
    {
      id: 3,
      question: "Hoe houd je je progressie bij?",
      options: [
        { id: 'a', text: "Ik noteer alles en hou het goed bij", result: "tracking_all" },
        { id: 'b', text: "Soms noteer ik wat, maar niet structureel", result: "tracking_sometimes" },
        { id: 'c', text: "Ik heb geen idee hoe ik dit moet doen", result: "no_tracking" },
        { id: 'd', text: "Ik volg gewoon op gevoel hoe het gaat", result: "guessing" }
      ]
    },
    {
      id: 4,
      question: "Hoe bepaal je hoeveel gewicht, sets en herhalingen je moet doen?",
      options: [
        { id: 'a', text: "Ik gok of volg algemene schema's", result: "guessing" },
        { id: 'b', text: "Mijn coach of trainer helpt mij hiermee", result: "coached" },
        { id: 'c', text: "Ik gebruik een systeem, maar weet niet of het klopt", result: "unsure_system" },
        { id: 'd', text: "Ik heb geen idee hoe ik dit moet aanpakken", result: "no_idea" }
      ]
    },
    {
      id: 5,
      question: "Krijg je momenteel begeleiding bij je techniek en uitvoering?",
      options: [
        { id: 'a', text: "Ja, maar alleen af en toe", result: "occasional_guidance" },
        { id: 'b', text: "Nee, ik doe alles zelf en kijk soms video's", result: "no_guidance" },
        { id: 'c', text: "Ik wil graag meer feedback, want ik weet niet of ik het goed doe", result: "wants_feedback" },
        { id: 'd', text: "Ik train al een tijdje en voel me zelfverzekerd over mijn techniek", result: "confident_technique" }
      ]
    },
    {
      id: 6,
      question: "Hoe gemotiveerd ben je om consistent te trainen?",
      options: [
        { id: 'a', text: "Ik ben supergemotiveerd", result: "highly_motivated" },
        { id: 'b', text: "Ik heb een stok achter de deur nodig", result: "needs_push" },
        { id: 'c', text: "Ik verlies vaak motivatie na een paar weken", result: "loses_motivation" },
        { id: 'd', text: "Ik heb moeite om gemotiveerd te blijven als ik geen snelle progressie zie", result: "progress_based_motivation" }
      ]
    }
  ]
};

const generateLocalSummary = (answers) => {
  console.log('Generating local summary for:', answers);
  
  if (!Array.isArray(answers) || answers.length < 6) {
    return 'We hebben niet genoeg informatie om een goed advies te geven. Vul de vragenlijst opnieuw in.';
  }

  try {
    const [goal, plan, tracking, programming, technique, motivation] = answers;
    let summary = '';

    // Doelgerichte intro
    if (goal.result === 'muscle_mass') {
      summary += 'Meer spiermassa opbouwen? Dan heb je een duidelijk plan nodig met de juiste opbouw en oefeningen. ';
    } else if (goal.result === 'strength') {
      summary += 'Wil je sterker worden? Dan is een gestructureerd krachtprogramma met de juiste progressie nodig. ';
    } else if (goal.result === 'fat_loss') {
      summary += 'Vet verliezen en strakker worden? Dit lukt met een combinatie van training en een slim schema. ';
    } else if (goal.result === 'better_health') {
      summary += 'Je wilt fitter en energieker worden. Een goed plan helpt je dit vol te houden. ';
    } else if (goal.result === 'clear_plan') {
      summary += 'Je zoekt een duidelijk plan. Zonder structuur is progressie lastig. ';
    }

    // Uitdagingen aanpakken
    if (plan.result === 'no_plan' || plan.result === 'inconsistent_plan' || plan.result === 'never_had_plan') {
      summary += 'Zonder plan train je zonder richting. Een goed schema geeft je structuur en resultaat. ';
    }
    if (technique.result === 'no_guidance' || technique.result === 'wants_feedback') {
      summary += 'Zonder goede techniek train je minder effectief en loop je risico op blessures. ';
    }
    if (motivation.result === 'needs_push' || motivation.result === 'loses_motivation' || motivation.result === 'progress_based_motivation') {
      summary += 'Motivatie vasthouden is lastig. Een plan helpt je consistent te blijven. ';
    }

    // Extra waarde
    if (tracking.result !== 'tracking_all') {
      summary += 'Zonder je progressie bij te houden weet je niet of je vooruitgang boekt. ';
    }
    if (programming.result === 'guessing' || programming.result === 'unsure_system' || programming.result === 'no_idea') {
      summary += 'Train niet op gevoel. Een goed plan vertelt je precies welke gewichten en herhalingen je nodig hebt. ';
    }

    return summary.trim();
  } catch (error) {
    console.error('Error generating local summary:', error);
    return 'We hebben niet genoeg informatie om een goed advies te geven. Vul de vragenlijst opnieuw in.';
  }
};

const determineChallenge = (answers) => {
  if (!answers || answers.length < 6) return 'consistentie in je training';

  const [goal, plan, tracking, programming, technique, motivation] = answers;

  if (plan.result === 'no_plan' || plan.result === 'inconsistent_plan') {
    return 'een gebrek aan structuur';
  }

  if (technique.result === 'no_guidance' || technique.result === 'wants_feedback') {
    return 'onzekerheid over techniek';
  }

  if (motivation.result === 'loses_motivation' || motivation.result === 'needs_push' || motivation.result === 'progress_based_motivation') {
    return 'motivatie';
  }

  return 'consistentie in je training';
};

const SignupForm = ({ onComplete, answers, aiSummary }) => {
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    email: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      // Format the quiz data
      const quizResults = answers.map((answer, index) => {
        return {
          question: quizData.questions[index].question,
          answer: answer.text,
          result: answer.result // if you want to include the result code
        }
      });

      // Create a single JSON object with all data
      const makeData = {
        userData: {
          name: formData.name,
          last_name: formData.last_name,
          email: formData.email,
          phone: formData.phone || 'Not provided'
        },
        quizResults: JSON.stringify(quizResults), // Convert array to JSON string
        aiSummary: aiSummary,
        timestamp: new Date().toISOString()
      };

      const currentDomain = window.location.hostname;
      let MAKE_WEBHOOK_URL;

      if (!currentDomain.includes('localhost')) {
        ReactPixel.track('Lead', {
          value: 14.50,
          currency: "EUR",
          status: 'free_trial_signup'
        });
        
        MAKE_WEBHOOK_URL = 'https://hook.eu2.make.com/nn5x6vho9okdr4r4wezdrvldn3huxmnm';
      } else {
        console.log('localhost')
        MAKE_WEBHOOK_URL = 'https://hook.eu2.make.com/nn5x6vho9okdr4r4wezdrvldn3huxmnm';
      }

      // Make.com webhook URL
      const response = await fetch(MAKE_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(makeData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setIsSubmitted(true);
      console.log('Form submitted successfully:', makeData);
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Er is iets misgegaan. Probeer het later opnieuw.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="text-center py-8">
        <div className="mb-6">
          <svg style={{ maxWidth: '60px' }} className="w-16 h-16 text-green-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="text-2xl font-bold mb-4">Bedankt!</h3>
        <p className="text-gray-600">
          We hebben je gegevens ontvangen. Bekijk je e-mail voor je persoonlijke plan.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6 flex text-left flex-col gap-4">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
          {error}
        </div>
      )}
      <div className="text-left">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
          Voornaam
        </label>
        <input
          type="text"
          id="name"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          disabled={isSubmitting}
        />
      </div>
      <div className="text-left">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 mb-1">
          Achternaam
        </label>
        <input
          type="text"
          id="last_name"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          value={formData.last_name}
          onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
          disabled={isSubmitting}
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
          E-mailadres
        </label>
        <input
          type="email"
          id="email"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          disabled={isSubmitting}
        />
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-red-600 text-white font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center">
            <Loader2 className="w-5 h-5 animate-spin mr-2" />
            Wordt verzonden...
          </div>
        ) : (
          'Ja, stuur mij mijn gratis plan!'
        )}
      </button>
    </form>
  );
};

const QuizResults = ({ answers, onComplete }) => {
  const [showSignup, setShowSignup] = useState(false);

  const challenge = determineChallenge(answers);

  const handleShowSignup = () => {
    setShowSignup(true);
  };

  if (showSignup) {
    return <SignupForm onComplete={onComplete} answers={answers} />;
  }

  return (
    <div className="space-y-8">
      <div className="text-left">
        <h2 className="text-3xl text-black font-bold mb-4">Jouw Persoonlijke Trainingsplan Wacht Op Jou!</h2>
        <p className="text-gray-700">
          {generateLocalSummary(answers)}
          <br /><br />
          Op basis van jouw antwoorden maken we een <b>gratis</b> persoonlijk trainingsplan. Dit plan is afgestemd op jouw doelen en niveau. 
          <br /><br />
          Om ervoor te zorgen dat het perfect bij je past, stellen we nog een paar korte vragen in onze intake. Na het invullen ontvang je binnen enkele uren jouw gratis persoonlijke plan. 
          <br /><br />
          Wil je jouw gratis trainingsplan ontvangen? Vul hieronder je naam en e-mailadres in en we sturen direct de intake naar je op!
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <button
          onClick={handleShowSignup}
          className="flex-1 bg-red-600 text-white font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors"
        >
          Ja, ik wil een gratis plan!
        </button>
        <button
          onClick={onComplete}
          className="bg-gray-200 text-gray-700 font-semibold px-8 py-4 rounded-lg text-lg hover:bg-gray-300 transition-colors"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

const QuizModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleQuizOpen = async () => {
    try {
      await fetch('https://hook.eu2.make.com/gtmbqn4i2y6lq6dyut1x3bcl4tkyrwn6', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: 'quiz_opened',
          timestamp: new Date().toISOString()
        })
      });
    } catch (error) {
      console.error('Failed to log quiz open:', error);
    }
    setIsOpen(true);
  };

  return (
    <div id="offer" className="waarom-section" style={{ background: 'var(--red)'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
      {/* Button to open the quiz */}
      <h2 className="text-3xl text-white font-bold mb-3">Ontdek jouw ideale aanpak voor maximale spiergroei</h2>
      <p className="text-white">
      Beantwoord 6 korte vragen en ontdek wat jou tegenhoudt én hoe je jouw doelen kunt bereiken. Simpel, snel en persoonlijk.
      </p>
      <button
        onClick={handleQuizOpen}
        className="inline-block bg-white mt-5 text-black font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors"
      >
        Start mijn Quiz
      </button>

      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-gray-100 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
            {/* Close button */}
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-4 right-4 p-2 hover:bg-gray-200 rounded-full transition-colors"
            >
              <X className="w-6 h-6 text-gray-500" />
            </button>

            {/* Quiz Content */}
            <FitnessQuiz onComplete={() => setIsOpen(false)} />
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
    </div>
  );
};

const FitnessQuiz = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [aiSummary, setAiSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generateAISummary = async (userAnswers) => {
    setIsLoading(true);
    try {
      console.log('User answers:', userAnswers);
      console.log('Number of questions:', quizData.questions.length);

      const summary = generateLocalSummary(userAnswers);
      setAiSummary(summary);
      setShowResults(true);
    } catch (error) {
      console.error('Failed to generate AI summary:', error);
      // Fallback to local generation
      const summary = generateLocalSummary(userAnswers);
      setAiSummary(summary);
      setShowResults(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswer = async (answer) => {
    // Only add answer if we haven't reached the maximum number of questions
    if (answers.length < quizData.questions.length) {
      const newAnswers = [...answers, answer];
      setAnswers(newAnswers);
      
      if (currentQuestion + 1 < quizData.questions.length) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowResults(false); // Make sure results aren't shown during loading
        await generateAISummary(newAnswers);
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setAnswers(answers.slice(0, -1));
    }
  };

  // Show loading screen
  if (isLoading) {
    return (
      <div className="w-full max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl p-8 text-center">
          <div className="flex flex-col items-center justify-center py-12">
            <Loader2 className="w-12 h-12 animate-spin text-red-600 mb-4" />
            <p className="text-xl text-gray-600">
              Je persoonlijke advies wordt gegenereerd...
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Dit kan enkele seconden duren
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Show results
  if (showResults) {
    return (
      <div className="w-full max-w-4xl mx-auto px-4 py-8">
        <div className="rounded-xl">
          <QuizResults
            answers={answers}
            aiSummary={aiSummary}
            isLoading={isLoading}
            onComplete={onComplete}
          />
        </div>
      </div>
    );
  }

  // Show questions
  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <div className="">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-6">
            <span className="text-sm font-semibold text-gray-500">
              Vraag {currentQuestion + 1} van {quizData.questions.length}
            </span>
            {currentQuestion > 0 && (
              <button 
                onClick={handlePrevious}
                className="flex items-center text-gray-500 hover:text-gray-700"
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Vorige
              </button>
            )}
          </div>
          <h2 className="text-2xl text-black font-bold mb-4">
            {quizData.questions[currentQuestion].question}
          </h2>
        </div>

        <div className="space-y-4">
          {quizData.questions[currentQuestion].options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleAnswer(option)}
              className="w-full text-left p-4 rounded-lg border-2 border-gray-200 hover:border-red-500 transition-colors flex items-center justify-between group"
            >
              <span className="text-gray-700 group-hover:text-red-600">
                {option.text}
              </span>
              <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-red-500" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuizModal;