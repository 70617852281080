import './Instagram.css';

function Insta() {
 
  return (
    <div className="waarom-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading mb-5 centered dark-heading">
              <h6>Instagram</h6>
              <h2 className="mt-2 mb-4 font-bold">@stefridder</h2>
              <p>
                Check onze laatste posts
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="insta-posts">
              <div className="insta-item">
                <a href="https://www.instagram.com/p/C4Nj2rrtyR1/?img_index=1" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on March 07, 2024. May be an image of 1 person and text that says 'WAAROM BOUWEN SOMMIGE MENSEN SNELLER SPIERMASSA OP DAN ANDEREN? HealthyLivingProject @stefridder'." crossOrigin="anonymous" src="/img/insta-1.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
              <div className="insta-item">
                <a href="https://www.instagram.com/p/C4HxipDtM3c/?img_index=1" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on March 04, 2024. May be an image of text that says 'AUTOFAGIE DOOR VASTEN: ECHT OF ONZIN? HealthyLivingProject ect Healthy yLivingProject Living @stefridder project'." crossOrigin="anonymous" src="/img/insta-2.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
              <div className="insta-item">
                <a href="https://www.instagram.com/p/C4C6B68y7D1/" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on March 04, 2024. May be an image of text that says 'AUTOFAGIE DOOR VASTEN: ECHT OF ONZIN? HealthyLivingProject ect Healthy yLivingProject Living @stefridder project'." crossOrigin="anonymous" src="/img/rug.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
              <div className="insta-item">
                <a href="https://www.instagram.com/p/C4A0KHdtZwt/?img_index=1" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on March 02, 2024. May be an image of 1 person and text that says 'KRACHTTRAINING VERMINDERT DEPRESSIE BIJ VOLWASSENEN HealthyLivingProject Proj ect Healthy Living @stefridder @healthyli Blijkt Blijktuiteenrecentestudie uit een recente studie'." crossOrigin="anonymous" src="/img/insta-3.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
              <div className="insta-item">
                <a href="https://www.instagram.com/p/C39n1LQtuOO/?img_index=1" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on February 29, 2024. May be an image of bureau and text that says 'SNELLE TIPS OM EXPERTS TE IDENTIFICEREN HealthyLivingProject ject Healthy Living hyLivingProject @stefridder'." crossOrigin="anonymous" src="/img/insta-4.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
              <div className="insta-item mobile">
                <a href="https://www.instagram.com/p/C35uv1kyKmf/" target="_blank">
                  <div className="insta-overlay">
                    <img className="insta-img" src="https://cdn.shopify.com/s/files/1/0379/0431/8596/files/Instagram_icon_svg.svg?v=1707498362" height="17" width="17" /> 
                  </div>
                  <img alt="Photo by Healthy Living Project on February 29, 2024. May be an image of bureau and text that says 'SNELLE TIPS OM EXPERTS TE IDENTIFICEREN HealthyLivingProject ject Healthy Living hyLivingProject @stefridder'." crossOrigin="anonymous" src="/img/insta-5.png" className="x5yr21d xu96u03 x10l6tqk x13vifvy x87ps6o xh8yej3" style={{ objectFit: 'cover' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Insta;